import { lazy } from 'react-router-guard';
import { noAuth, auth } from "./guards/guards";

export default [
  {
    path: '/logIn',
    canActivate: [noAuth],
    component: lazy(() => import('./containers/login'))
  },
  {
    path: "/resetPassword",
    canActivate: [noAuth],
    component: lazy(() => import("./containers/resetPassword"))
  },
  {
    path: '',
    canActivate: [auth],
    component: lazy(() => import('./layouts/mainLayout')),
    routes: [
      {
        path: '/brands',
        exact: true,
        component: lazy(() => import('./containers/brands'))
      },
      {
        path: '/vehicles',
        exact: true,
        component: lazy(() => import('./containers/vehicles'))
      },
      {
        path: '/vehicles/create',
        exact: true,
        component: lazy(() => import('./containers/vehicles/vehicleForm'))
      },
      {
        path: '/vehicles/details/:id/edit',
        exact: true,
        component: lazy(() => import('./containers/vehicles/vehicleForm'))
      },
      {
        path: "/vehicles/details/:id",
        exact: true,
        component: lazy(() => import("./containers/vehicles/vehicleDetails"))
      },
      {
        path: '/staffAdvisers',
        exact: true,
        component: lazy(() => import('./containers/staffUsers'))
      },
      {
        path: '/staffCoordinators',
        exact: true,
        component: lazy(() => import('./containers/staffUsers'))
      },
      {
        path: '/staffHumanManager',
        exact: true,
        component: lazy(() => import('./containers/staffUsers'))
      },
      {
        path: '/staffAdminAssistant',
        exact: true,
        component: lazy(() => import('./containers/staffUsers'))
      },
      {
        path: '/clientFields',
        exact: true,
        component: lazy(() => import('./containers/clientFields'))
      },
      {
        path: '/clients',
        exact: true,
        component: lazy(() => import('./containers/clients'))
      },
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('./containers/calendar'))
      }
    ]
  },
  {
    path: '**',
    redirect: ''
  }
];
