const jwt = require('jsonwebtoken');
const { postRequest } = require('./http-helper');
const tokenKey = process.env.REACT_APP_TOKEN_KEY;
const api = 'auth';

const isAuthenticated = () => {
    try {
        const token = localStorage.getItem(tokenKey);
        if (!token) {
            return false;
        }
        const decodedToken = jwt.decode(token, { complete: true });
        if (!decodedToken) {
            localStorage.clear();
            return false;
        }
        return decodedToken;
    } catch (error) {
        console.log(error);
        localStorage.clear();
        return false;
    }
}

const logIn = ({ email, password }) => {
    return new Promise((resolve, reject) => {
        email = email.toLowerCase();
        postRequest(`${api}/signIn`, { email, password }, resolve, reject, null, saveToken);
    });
};

const logOut = () => {
    localStorage.clear();
    window.location.reload();
}

const saveToken = ({ token }, resolve) => {
    localStorage.clear();
    localStorage.setItem(tokenKey, token);
    resolve(isAuthenticated().payload);
};

const sendResetPasswordCode = ({ email }) => {
    return new Promise((resolve, reject) => {
        email = email.toLowerCase();
        postRequest(`${api}/sendResetPasswordCode`, { email }, resolve, reject, null, '');
    });
};

const resetPassword = ({ email, newPassword, token }) => {
    return new Promise((resolve, reject) => {
        email = email.toLowerCase();
        postRequest(`${api}/resetPassword`, { email, newPassword, token }, resolve, reject, null, '');
    });
};

module.exports = { isAuthenticated, logIn, logOut, resetPassword, sendResetPasswordCode };